:root {
  --font-galadali: "Galadali", serif;
  --font-inter: "Inter", sans-serif;
}

.galadali {
  font-family: var(--font-galadali);
}

.inter {
  font-family: var(--font-inter);
}

body {
  font-family: var(--font-inter);
  color: var(--white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-galadali);
}

i,
.italic {
  font-style: italic;
}

.thin {
  font-weight: 100;
}

.extra-light {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.underlined {
  text-decoration: underline;
}

.align-center {
  text-align: center;
}

h2 {
  font-size: 5.8rem;
  line-height: 5.3rem;

  @media (--laptop) {
    font-size: 16.2rem;
    line-height: 14rem;
  }
}

h3 {
  font-size: 5.8rem;

  @media (--laptop) {
    font-size: 8rem;
  }
}

.p1 {
  font-size: 1.8rem;
}

.p2 {
  font-size: 1.6rem;

  @media (--laptop) {
    font-size: 2.4rem;
  }
}

.p3 {
  font-size: 2rem;

  @media (--laptop) {
    font-size: 3.2rem;
  }
}

.p4 {
  font-size: 1.6rem;
}

.p5 {
  font-size: 1rem;

  @media (--laptop) {
    font-size: 1.6rem;
  }
}

.p6 {
  font-size: 1.2rem;
}

.p7 {
  font-size: 2rem;

  @media (--laptop) {
    font-size: 2.4rem;
  }
}

.p8 {
  font-size: 4.2rem;

  @media (--laptop) {
    font-size: 4.2rem;
  }
}
