.bimbo {
  height: 100vh;
  padding: 21.4rem var(--margin) 0;
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
  align-items: center;

  @media (--laptop) {
    padding-top: 32rem;
  }

  &__text {
    width: 100%;
    font-size: 4.2rem;
    line-height: 4.2rem;

    @media (--laptop) {
      width: var(--columns-10);
    }
  }

  &__gif {
    width: 100%;
    height: 43rem;
    background-image: url(https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExeGY4N3JvcXNyZGhrN3cyd3Vuemt4OHloZDA3c2IycHdnajd6Mmk5eCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/YqWBUAQAKbraw/giphy.webp);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media (--laptop) {
      width: 48rem;
    }
  }
}
