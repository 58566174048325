.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0 var(--margin) 4rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  row-gap: 6.4rem;

  @media (--laptop) {
    padding: 40rem var(--margin) 11.6rem;
    row-gap: 9.4rem;
    height: fit-content;
    min-height: 100vh;
  }

  &__background.image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #092520;
      mix-blend-mode: hard-light;
    }
  }

  &__play {
    width: var(--columns-3);
    height: var(--columns-3);
    z-index: 1;
    position: relative;

    @media (--laptop) {
      width: 32rem;
      height: 32rem;
      cursor: pointer;
    }

    svg {
      width: 100%;
      height: 100%;

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      > g {
        transform-origin: center;
        animation: rotation 32s linear infinite;
      }

      circle,
      > path {
        fill: transparent;
      }

      circle,
      > path {
        will-change: fill, stroke;
        transition: var(--transition-duration--fast)
          var(--transition-ease--out-quint);
        transition-property: fill, stroke;
      }
    }

    &:hover {
      svg {
        circle {
          fill: var(--pink);
          stroke: var(--pink);
        }

        path {
          fill: white;
        }
      }
    }
  }

  &__claim {
    width: 100%;
    position: relative;
    z-index: 1;
    text-align: center;

    &::before,
    &::after {
      content: "";
      width: 3.2rem;
      height: 4.4rem;
      position: absolute;
      bottom: 0;
      left: 3.6rem;
      background-image: url(/assets/icons/scoll-down.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @media (--laptop) {
        width: 6.3rem;
        height: 8.6rem;
        bottom: 2.8rem;
      }
    }

    &::after {
      left: auto;
      right: 3.6rem;
    }
  }
}
