.carousel {
  user-select: none;
  width: 100%;
  overflow: hidden;

  @media (--laptop) {
  }

  &__items {
    @media (--laptop) {
    }

    &__item {
    }
  }
}
