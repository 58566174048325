.raccolta {
  width: 100%;
  padding: 6rem var(--margin);
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  align-items: center;

  @media (--laptop) {
    padding: 9.6rem calc(var(--margin) + var(--full-column));
  }

  h2.raccolta__title {
    @media (--laptop) {
      position: absolute;
      left: calc(var(--columns-13) + var(--gutter) + var(--margin));
      font-size: 9rem;
      line-height: 9rem;
    }
  }

  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 6.4rem;

    @media (--laptop) {
      row-gap: 0;
    }

    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 3.2rem;

      @media (--laptop) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      &:nth-child(even) {
        @media (--laptop) {
          flex-direction: row-reverse;
          margin-top: -33.8rem;
        }
      }

      &__image {
        width: 100%;
        aspect-ratio: 353/403;

        @media (--laptop) {
          width: var(--columns-11);
          aspect-ratio: 761/890;
        }

        &--small {
          aspect-ratio: 350/290;

          @media (--laptop) {
            width: var(--columns-10);
            aspect-ratio: 690/758;
          }
        }

        .image {
          width: 100%;
          height: 100%;
        }
      }

      &__text {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (--laptop) {
          width: var(--columns-10);
          margin-top: 17rem;
        }

        .raccolta__items__item:nth-child(even) & {
          @media (--laptop) {
            width: var(--columns-11);
            margin-top: calc(7.4rem + 33.8rem);
          }
        }

        &__text {
          width: var(--columns-4);

          @media (--laptop) {
            width: var(--columns-8);
          }

          .raccolta__items__item:nth-child(even) & {
            @media (--laptop) {
              width: var(--columns-9);
            }
          }
        }
      }
    }
  }
}
