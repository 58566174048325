.footer {
  padding: 6rem var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6.2rem;
  z-index: var(--footer-z-index);
  opacity: 1;
  transition: opacity var(--transition-duration--slower)
    var(--transition-ease--out-expo);

  .loader-hidden & {
    opacity: 1;
  }

  @media (--laptop) {
    padding: 15rem calc(var(--margin) + var(--columns-3) + var(--gutter)) 9rem;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: calc(var(--columns-4) + (2 * var(--gutter)));
    align-items: center;
  }

  &__logo {
    width: 9.4rem;
    height: 9.4rem;
    background-image: url(/assets/icons/viri_dian_logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media (--laptop) {
      width: var(--columns-2);
      height: var(--columns-2);
      order: 1;
    }
  }

  &__utils {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--laptop) {
      width: var(--columns-2);
      order: 3;
      align-items: flex-end;
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 0.8rem;
      align-items: center;

      @media (--laptop) {
        align-items: flex-end;
      }
    }
  }

  &__info {
    width: var(--columns-4);
    text-align: center;
    margin-top: 3rem;

    @media (--laptop) {
      width: var(--columns-6);
      order: 2;
      text-align: left;
      margin-top: 0;
    }
  }
}
