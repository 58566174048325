.header {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6rem 0;
  z-index: var(--header-z-index);
  opacity: 1;
  transition: opacity var(--transition-duration--slower)
      var(--transition-ease--out-expo),
    transform var(--transition-duration--slow) var(--transition-ease--out-quint);
  position: absolute;
  top: 0;
  left: 0;

  .loader-hidden & {
    opacity: 1;
  }

  @media (--laptop) {
    padding: 2.4rem var(--margin);
  }

  &--hidden {
    transform: translateY(-100%);
  }

  &__logo {
    @media (--laptop) {
      width: var(--columns-8);
    }

    h1 {
      width: 9.4rem;
      height: 9.4rem;
      background-image: url(/assets/icons/viri_dian_logo.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto;

      @media (--laptop) {
        width: 13rem;
        height: 13rem;
      }
    }
  }

  ul {
    @media (--laptop) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: var(--gutter);
    }
  }

  &__anchor {
    display: none;

    @media (--laptop) {
      width: var(--columns-4);
      display: block;
      text-align: center;
    }
  }
}
