:root {
  --green: #092520;
  --pink: #e14c73;
  --white: #ffffff;
}

.c-green {
  color: var(--green);
}

.c-pink {
  color: var(--pink);
}

.c-white {
  color: var(--white);
}

.bc-green {
  background-color: var(--green);
}

.bc-pink {
  background-color: var(--pink);
}

.bc-white {
  background-color: var(--white);
}
