.promo-video-modal {
  video {
    border-radius: 1.2rem;
  }

  &.modal&::after {
    background-color: rgba(0, 0, 0, 0.95);
  }

  &.modal--video .modal__close {
    background-color: transparent;
  }

  &__controls {
    width: 100%;
    position: absolute;
    top: calc(50vh + 50% - 30px);
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-100%);

    @media (--laptop) {
      top: calc(100% + 1.6rem);
      transform: none;
    }

    &__control {
      width: var(--column);
      overflow: hidden;
      cursor: pointer;
      flex-shrink: 0;

      > p {
        will-change: transform;
        transition: transform var(--transition-duration--faster)
          var(--transition-ease--out-quint);
        user-select: none;
      }

      &--mu {
        text-align: right;
      }
    }

    &__progress-bar {
      width: 100%;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.2);
      position: relative;
      border-radius: 1.2rem;
      cursor: pointer;

      &__current {
        width: 100%;
        height: 100%;
        border-radius: 1.2rem;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--pink);
        transform-origin: center left;
        transition: transform var(--transition-duration--faster)
          var(--transition-ease--out-quint);
      }

      &__clickable-area {
        width: 100%;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        opacity: 0;
        z-index: 2;
      }
    }
  }
}
