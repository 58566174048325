.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--modal-z-index);
  overflow: auto;
  visibility: hidden;
  transition: visibility var(--transition-duration--normal) step-end;
  opacity: 0;

  @media (--tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .loaded & {
    opacity: 1;
  }

  &--video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &--open {
    visibility: visible;
    transition-timing-function: step-start;
  }

  &__video {
    width: 100%;
    height: 100%;
    position: relative;

    video {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__inner {
    width: calc(100% - 2rem);
    margin: 9.4rem auto 4.2rem;
    position: relative;
    z-index: 2;
    opacity: 0;
    transform: translateY(100px);
    transition: var(--transition-duration--normal)
      var(--transition-ease--out-quint);
    transition-property: opacity, transform;

    @media (--tablet) {
      display: flex;
      flex-direction: row;
      gap: 2px;
      width: var(--columns-4);
      margin: 0 auto;
      aspect-ratio: 1.589928058;
    }

    @media (--laptop) {
      width: var(--columns-8);
    }

    .modal--video & {
      margin-top: 0;
      margin-bottom: 0;

      @media (--laptop) {
        width: var(--columns-18);
      }
    }

    .modal--open & {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0s;
    }

    .modal--video & {
      aspect-ratio: 16/9;
    }
  }

  &__intro,
  &__form-area {
    padding: 4rem 3rem;
    border-radius: 1.2rem;
    position: relative;
    z-index: 2;

    @media (--tablet) {
      flex: 0 0 calc(50% - 1px);
    }
    @media (--laptop) {
      padding: 5rem;
    }
  }

  &__intro {
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;
    color: var(--black);

    @media (--tablet) {
      margin-bottom: 0;
    }

    &,
    .modal--moave & {
      background-color: var(--mauve);
    }

    .modal--lime & {
      background-color: var(--lime);
    }

    &__logos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
    }
  }

  &__intro-title {
    max-width: 23rem;
    margin-bottom: 3rem;
    font-weight: 200;
    font-size: 4.4rem;
    line-height: 0.9em;

    @media (--laptop) {
      max-width: 32rem;
      font-size: 6.8rem;
      margin-bottom: 6rem;
    }
  }

  &__intro-text {
    max-width: 23rem;
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.4em;

    @media (--laptop) {
      max-width: 15rem;
    }
  }

  &__intro-logo {
    width: 14.2rem;
    height: 3rem;

    &:nth-child(2) {
      background-position: center right;
    }
  }

  &__form-area {
    background-color: var(--white);
    color: var(--black);
  }

  &__close {
    width: 5rem;
    height: 5rem;
    padding: 1.8rem;
    margin-bottom: 0.2rem;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
    border-radius: 50%;
    opacity: 0;
    transform: translateY(100%);

    transition: opacity var(--transition-duration--faster)
        var(--transition-ease--out-quint),
      transform var(--transition-duration--faster)
        var(--transition-ease--out-quint);

    @media (--laptop) {
      width: 6rem;
      height: 6rem;
      margin-left: 0.2rem;
      top: 0;
      left: 100%;
      transform: translateY(0);
    }

    &,
    .modal--moave & {
      background-color: var(--mauve);

      @media (--tablet) {
        background-color: var(--white);
      }
    }

    .modal--lime & {
      background-color: var(--lime);

      @media (--tablet) {
        background-color: var(--white);
      }
    }

    .modal--video & {
      background-color: var(--white);
    }

    .modal--open & {
      opacity: 1;
    }

    &::after {
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
      background: no-repeat url("/assets/icons/close.svg") center / 1.6rem
        1.6rem;
      transition: transform var(--transition-duration--fast)
        var(--transition-ease--out-quint);

      @media (--laptop) {
        width: 2.4rem;
        height: 2.4rem;
        background: no-repeat url("/assets/icons/close.svg") center / 2.4rem
          2.4rem;
      }
    }

    @media (hover: hover) {
      &:hover::after {
        @media (--laptop) {
          transform: translate3d(-50%, -50%, 0) rotate(180deg);
        }
      }
    }
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity var(--transition-duration--faster)
      var(--transition-duration--fastest) var(--transition-ease--out-quint);
  }

  &--open::after {
    opacity: 1;
    transition-delay: 0s;
  }
}
