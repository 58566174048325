.oro {
  width: 100%;
  padding: 6rem var(--margin);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4.8rem;

  @media (--laptop) {
    padding: 6.4rem var(--margin) 9rem;
    row-gap: 10rem;
  }

  &__images {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__image.image {
      width: 23.5rem;
      height: 18.6rem;
      flex-shrink: 0;
      transform: rotate(-4deg);

      @media (--laptop) {
        width: 55.9rem;
        height: 39.6rem;
      }

      &:nth-child(even) {
        transform: rotate(4deg);
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--pink);
        mix-blend-mode: plus-lighter;
      }
    }
  }

  &__text {
    @media (--laptop) {
      width: var(--columns-14);
    }
  }
}
