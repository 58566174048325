.serve {
  width: 100%;
  padding: 6rem 0 5.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 8rem;
  align-items: center;

  @media (--laptop) {
    padding: 2rem var(--margin) 12rem;
  }

  h2.serve__title {
    font-size: 9.6rem;
    line-height: 8rem;

    @media (--laptop) {
      font-size: 26.3rem;
      line-height: 26.3rem;
    }
  }

  &__items-wrapper {
    width: 100%;
    position: relative;

    @media (--laptop) {
      width: var(--columns-20);
    }

    &__decorator {
      width: 12.1rem;
      height: 12.1rem;
      position: absolute;
      right: 2.4rem;
      top: -9.6rem;
      background-image: url(/assets/icons/viridian_serve_badge.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      z-index: 1;

      @media (--laptop) {
        width: 20rem;
        height: 20rem;
        top: -14.8rem;
        right: auto;
        left: var(--columns-5);
      }
    }
  }

  &__items-carousel {
    width: 100%;
    overflow: hidden;

    @media (--laptop) {
      pointer-events: none;
    }
  }

  &__items {
    width: fit-content;
    padding: 0 var(--margin);
    display: flex;
    justify-content: flex-start;
    column-gap: var(--gutter);

    @media (--laptop) {
      column-gap: calc(var(--column) + (2 * var(--gutter)));
      padding: 0;
    }

    &__item {
      width: var(--columns-4);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 2rem;

      @media (--laptop) {
        width: var(--columns-6);
        row-gap: 2.8rem;
      }

      &__image {
        width: 100%;
        aspect-ratio: 278/414;
        position: relative;

        @media (--laptop) {
          aspect-ratio: 408/540;
        }

        &__title {
          position: absolute;
          top: 2.4rem;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
        }

        .image {
          width: 100%;
          height: 100%;
        }
      }

      &__text {
        @media (--laptop) {
          font-size: 2.4rem;
          line-height: 3.2rem;
        }
      }
    }
  }
}
