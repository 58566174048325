.age-verification {
  width: 100vw;
  height: 100vh;
  padding: 0 var(--margin);
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--cookie-banner-z-index);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: opacity var(--transition-duration--fast)
    var(--transition-ease--out-quint);
  will-change: opacity;
  opacity: 0;

  @media (--laptop) {
  }

  &--visible {
    opacity: 1;
    pointer-events: all;
  }

  &__popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 6.4rem;
    align-items: center;
    padding: 4rem 4rem;

    @media (--laptop) {
      width: 64rem;
      padding: 7rem 6rem;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;
    align-items: center;
  }

  &__buttons {
    display: flex;
    column-gap: 4.8rem;

    &__button {
      font-size: 5.8rem;
      padding: 0 3.2rem;
      background-color: var(--white);
      color: var(--pink);

      @media (--laptop) {
        padding: 0;
        width: var(--columns-3);
        font-size: 6rem;
        text-align: center;
        will-change: background-color, color;
        transition: var(--transition-duration--fast)
          var(--transition-ease--out-quint);
        transition-property: background-color, color;
        border: 2px solid var(--pink);
        cursor: pointer;

        &:hover {
          color: var(--white);
          background-color: var(--pink);
        }
      }
    }
  }
}
