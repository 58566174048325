.provalo {
  width: 100vw;
  padding: 6rem var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.4rem;
  position: relative;

  @media (--laptop) {
    padding: 7.2rem calc(var(--margin) + var(--columns-3) + var(--gutter)) 15rem;
  }

  &__decorator {
    width: 10rem;
    height: 11rem;
    background-image: url(/assets/icons/viridian-glass.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: -4rem;
    right: var(--margin);
    transform: rotate(10deg);

    @media (--laptop) {
      width: 23rem;
      height: 25rem;
      top: -5.6rem;
      right: calc(var(--margin) + var(--columns-1));
    }
  }

  &__title {
    width: var(--columns-5);
    overflow: hidden;

    @media (--laptop) {
      width: 100%;
      font-size: 12rem;
      line-height: 12rem;
    }
  }

  &__text {
    width: var(--columns-5);
    overflow: hidden;

    @media (--laptop) {
      width: var(--columns-10);
    }
  }

  &__feedback {
    padding: 0.8rem 2.4rem;
    background-color: #0a5147;
    margin-top: 2rem;
    border-radius: 10rem;
    opacity: 0;
    will-change: opacity;
    transition: opacity var(--transition-duration--fast)
      var(--transition-ease--out-quint);

    @media (--laptop) {
      padding: 0.8rem 4rem;
    }

    &--visible {
      opacity: 1;
    }
  }

  &__form {
    width: var(--columns-5);
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 7.2rem;
    position: relative;

    @media (--laptop) {
      width: 100%;
      margin-top: 2rem;
    }

    &__fields {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 3.2rem;

      @media (--laptop) {
        display: grid;
        grid-template-columns: repeat(18, var(--column));
        column-gap: var(--gutter);
        row-gap: 8rem;
      }

      &__checkboxes {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 4.8rem;

        @media (--laptop) {
          grid-column: 1 / 10;
          padding-bottom: 0.8rem;
        }
      }

      &__field {
        width: 100%;
        padding-bottom: 0.4rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        position: relative;

        @media (--laptop) {
          grid-column: 1 / 10;
          padding-bottom: 0.8rem;
        }

        &:not(.provalo__form__fields__field--checkbox)&::before {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: -1px;
          left: 0;
          background-color: currentColor;
          transform-origin: center right;
          transform: scaleX(0);
          will-change: transform;
          transition: transform var(--transition-duration--fast)
            var(--transition-ease--out-quint);
        }

        &:not(.provalo__form__fields__field--checkbox)&:focus-within::before {
          transform: scaleX(1);
          transform-origin: center left;
        }

        &:nth-child(even):not(.provalo__form__fields__field--checkbox) {
          @media (--laptop) {
            grid-column: 10 / 19;
          }
        }

        &--message {
          @media (--laptop) {
            grid-column: 1 / 19;
          }
        }

        &__input {
          color: currentColor;
          width: 100%;

          &::placeholder {
            color: currentColor;
            opacity: 0.6;
          }
        }

        &--checkbox {
          border: none;
          padding-bottom: 0;

          @media (--laptop) {
            grid-column: 1 / 10;
          }

          &:first-of-type {
            margin-top: 4rem;

            @media (--laptop) {
              margin-top: 0;
            }
          }

          &__input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
          }

          &__label {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;

            &__icon {
              width: 1.8rem;
              height: 1.8rem;
              border: 1px solid currentColor;
              position: relative;

              &::before {
                content: "";
                width: 50%;
                height: 50%;
                background-color: var(--pink);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                border-radius: 50%;
                will-change: transform;
                transition: transform var(--transition-duration--fast)
                  var(--transition-ease--out-quint);

                .provalo__form__fields__field--checkbox__input:checked
                  + .provalo__form__fields__field--checkbox__label
                  & {
                  transform: translate(-50%, -50%) scale(1);
                }
              }
            }
          }
        }

        &__error {
          opacity: 0;
          position: absolute;
          top: 100%;
          left: 0;
          transform: translateY(0.4rem);
          will-change: opacity;
          transition: opacity var(--transition-duration--fast)
            var(--transition-ease--out-quint);

          @media (--laptop) {
            transform: translateY(0.8rem);
          }

          .form__field--wrong & {
            opacity: 1;
          }
        }
      }
    }

    &__actions {
      @media (--laptop) {
        position: absolute;
        right: 0;
        bottom: 1.8rem;
      }

      &__button {
        font-size: 5.8rem;
        padding: 0 3.2rem;
        background-color: var(--pink);
        color: var(--white);

        @media (--laptop) {
          padding: 0;
          width: var(--columns-3);
          font-size: 6rem;
          text-align: center;
          transform: translateY(50%);
          color: var(--white);
          will-change: background-color, color;
          transition: var(--transition-duration--fast)
            var(--transition-ease--out-quint);
          transition-property: background-color, color;
          border: 2px solid var(--pink);
          cursor: pointer;

          &:hover {
            color: var(--pink);
            background-color: var(--white);
          }
        }
      }
    }
  }
}
