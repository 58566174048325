.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--loader-z-index);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  opacity: 1;
  color: var(--white);
  background-color: var(--green);
  will-change: opacity;
  transition: opacity var(--transition-duration--fast)
    var(--transition-ease--out-quint);

  &--hidden {
    opacity: 0;
  }

  &__logo {
    width: 16rem;
    height: 16rem;
    background-image: url(/assets/icons/viri_dian_logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
