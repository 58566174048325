:root {
  --marquee-translate-value: 200px;

  @media (--laptop) {
    --marquee-translate-value: 400px;
  }
}

.splash {
  width: 100vw;
  box-sizing: border-box;
  padding: calc(64 * var(--vrem)) var(--margin) 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;

  @media (--laptop) {
    padding: calc(99.3 * var(--vrem)) var(--margin) 14rem;
  }

  &__marquee {
    width: 120%;
    overflow: hidden;
    position: absolute;
    top: calc(41.4 * var(--vrem));
    transform: rotate(-8deg) translateY(-100%);
    clip-path: polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%);
    will-change: clip-path;
    transition: clip-path var(--transition-duration--fast)
      var(--transition-ease--out-quint);

    .loaded & {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }

    @media (--laptop) {
      padding: 2.4rem 0;
      top: calc(45.3 * var(--vrem));
      transform: rotate(-8deg) translateY(0);
    }

    .marquee__content {
      font-size: 2.4rem;
      font-weight: 500;

      @media (--laptop) {
        font-size: 4.8rem;
      }
    }
  }

  &__image {
    width: var(--columns-5);
    height: calc(34.8 * var(--vrem));
    position: absolute;
    top: calc(24 * var(--vrem));
    left: 50%;
    transform: translateX(-50%);
    background-image: url(https://i.imgur.com/aUghOUk.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media (--laptop) {
      height: calc(67 * var(--vrem));
    }
  }

  &__text {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3.6rem;

    @media (--laptop) {
      row-gap: 4.8rem;
    }

    h2 {
      width: 100%;
    }
  }

  #canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100 * var(--vh));
  }
}
