.essiccatura {
  width: 100%;
  padding: 2rem var(--margin) 6rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  align-items: center;

  @media (--laptop) {
    padding: 9.6rem calc(var(--margin) + var(--full-column));
    row-gap: 5.6rem;
  }

  &__image {
    width: 100%;
    aspect-ratio: 353/235;

    @media (--laptop) {
      aspect-ratio: 1538/729;
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3.2rem;

    @media (--laptop) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    h2.essiccatura__text__title {
      @media (--laptop) {
        font-size: 9rem;
        line-height: 9rem;
      }
    }

    &__text {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (--laptop) {
        width: var(--columns-11);
      }

      &__text {
        width: var(--columns-4);

        @media (--laptop) {
          width: var(--columns-9);
        }
      }
    }
  }
}
