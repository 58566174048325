.tabacco {
  width: 100%;
  padding: 4rem var(--margin) 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.4rem;

  @media (--laptop) {
    row-gap: 6.4rem;
    padding: 9rem var(--margin) 10rem;
  }

  &__title {
    @media (--laptop) {
      width: var(--columns-13);
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(5, var(--column));
    column-gap: var(--gutter);
    row-gap: var(--gutter);

    @media (--laptop) {
      grid-template-columns: repeat(20, var(--column));
      grid-template-rows: repeat(20, calc((63rem - var(--gutter) * 19) / 20));
    }

    &__image {
      &:nth-child(1) {
        grid-column: 1 / 3;
        aspect-ratio: 1/1;
        align-self: end;

        @media (--laptop) {
          grid-column: 3 / 6;
          grid-row-start: 12;
          align-self: auto;
        }
      }

      &:nth-child(2) {
        grid-column: 3 / 6;
        aspect-ratio: 1/1;

        @media (--laptop) {
          grid-column: 1 / 6;
          grid-row: 1 / 12;
          aspect-ratio: auto;
        }
      }

      &:nth-child(3) {
        grid-column: 1 / 6;
        aspect-ratio: 353/400;

        @media (--laptop) {
          grid-column: 6 / 16;
          aspect-ratio: 690/630;
        }
      }

      &:nth-child(4) {
        grid-column: 1 / 3;
        aspect-ratio: 129/115;

        @media (--laptop) {
          grid-column: 16 / 21;
          grid-row: 1 / 11;
          aspect-ratio: auto;
        }
      }

      &:nth-child(5) {
        grid-column: 3 / 6;
        aspect-ratio: 204/183;

        @media (--laptop) {
          grid-column: 16 / 20;
          grid-row: 11/ 21;
          aspect-ratio: auto;
        }
      }

      .image {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__text {
    margin-top: 1.2rem;

    @media (--laptop) {
      width: var(--columns-12);
      margin-top: 2.6rem;
    }
  }
}
