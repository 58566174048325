.pianta {
  width: 100%;
  padding: 6rem var(--margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.8rem;

  @media (--laptop) {
    padding: 9rem var(--margin) 13rem;
    row-gap: 10rem;
    row-gap: 4rem;
  }

  &__image {
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;

    @media (--laptop) {
      width: var(--columns-22);
      aspect-ratio: 1538/926;
      margin-bottom: 4rem;
    }

    &__decorator {
      width: 10rem;
      height: 10rem;
      position: absolute;
      top: -5rem;
      right: 1.6rem;
      background-image: url(/assets/icons/viridian_badge.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transform-origin: center;

      @media (--laptop) {
        width: 26.7rem;
        height: 26.7rem;
        top: -14.5rem;
        right: -7rem;
      }
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    @media (--laptop) {
      width: var(--columns-14);
    }
  }
}
