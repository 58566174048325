:root {
  --marquee-translate-value: 200px;

  @media (--laptop) {
    --marquee-translate-value: 400px;
  }
}

.product {
  width: 100%;
  padding: 12rem var(--margin) 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 14rem;
  position: relative;

  @media (--laptop) {
    padding: 42rem var(--margin) 13rem;
    row-gap: 50rem;
  }

  &__marquee__wrapper {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 6.8rem;
    left: 50%;
    transform: translateX(-50%);

    @media (--laptop) {
      top: 78rem;
    }
  }

  &__marquee {
    width: 120%;
    overflow: hidden;
    transform: rotate(-8deg);

    @media (--laptop) {
      padding: 2.4rem 0;
    }

    .marquee__content {
      font-size: 2.4rem;
      font-weight: 500;

      @media (--laptop) {
        font-size: 4.8rem;
      }
    }
  }

  &__bottle {
    width: 14rem;
    height: 32rem;
    background-image: url(https://i.imgur.com/aUghOUk.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: -6.4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media (--laptop) {
      width: var(--columns-8);
      height: 116rem;
      top: -18rem;
    }
  }

  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3.6rem;

    @media (--laptop) {
      row-gap: 4.8rem;
    }
  }
}
